
import { defineComponent, onMounted, onUpdated } from "vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { isDocPage } from "@/core/helpers/documentation";
import { asideTheme } from "@/core/helpers/config";
import base from "@/api/base.js";

export default defineComponent({
  name: "KTAside",

  created() {
    const loggedin = localStorage.getItem("userId");
    base
      .getUser(loggedin)
      .then((res) => {
        this.userData = res.data;
        console.log(res);
        if (this.userData.cmp.default_logo_use_yn === true) {
          this.logoSrc = "/companies/logo_image/1";
          console.log(this.logoSrc);
        } else {
          this.logoSrc = "/companies/logo_image/" + this.userData.cmp.id;
          console.log(this.logoSrc);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
  },

  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  data() {
    return {
      userData: { cmp: {} },
      logoSrc: [],
    };
  },

  setup() {
    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      isDocPage,
      asideTheme,
    };
  },
});
