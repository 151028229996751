
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MyInformModal from "@/views/auth/MyInform.vue";

export default defineComponent({
  name: "kt-user-menu",
  components: { MyInformModal },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const signOut = () => {
      // store.dispatch("logout").then(() => router.push({ name: "sign-in" }));
      store.dispatch("logout");
    };

    const setLang = lang => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    return {
      signOut,
      setLang,
      currentLanguage
    };
  }
});
