import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_2 = {
  key: 0,
  href: "#"
}
const _hoisted_3 = {
  key: 1,
  id: "kt_aside_toggle",
  class: "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
  "data-kt-toggle": "true",
  "data-kt-toggle-state": "active",
  "data-kt-toggle-target": "body",
  "data-kt-toggle-name": "aside-minimize"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1 rotate-180" }
const _hoisted_5 = { class: "aside-menu flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_KTMenu = _resolveComponent("KTMenu")

  return (_openBlock(), _createBlock("div", {
    id: "kt_aside",
    class: ["aside aside-hoverable", [
      (_ctx.asideTheme === 'light' || _ctx.isDocPage) && 'aside-light',
      _ctx.asideTheme === 'dark' && !_ctx.isDocPage && 'aside-dark',
    ]],
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createVNode("div", _hoisted_1, [
      (_ctx.asideTheme === 'dark' && !_ctx.isDocPage)
        ? (_openBlock(), _createBlock("a", _hoisted_2, [
            _createVNode("img", {
              src: _ctx.logoSrc,
              class: "h-40px "
            }, null, 8, ["src"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isDocPage)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotone/Navigation/Angle-double-left.svg" })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_KTMenu)
    ])
  ], 2))
}