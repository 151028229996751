import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-2 fs-6 w-150px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyInformModal = _resolveComponent("MyInformModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.signOut())),
          class: "menu-link px-5"
        }, " 로그아웃 ")
      ])
    ]),
    _createVNode(_component_MyInformModal)
  ], 64))
}