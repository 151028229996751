import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: ["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        container: !_ctx.footerWidthFluid
      }]
    }, null, 2)
  ]))
}