const MobileMenuConfig: object = [
  {
    heading: "메뉴",
    route: "/menu",
    pages: [
      {
        heading: "QSC 점검",
        route: "/qsc",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default MobileMenuConfig;
