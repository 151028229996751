const MainMenuConfig_Store: object = [
  {
    pages: [
      {
        heading: "대시보드",
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "메뉴",
    route: "/menu",
    pages: [
      {
        heading: "QSC 점검(매장)",
        route: "/menu/qsc/qsc-store",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "점검 결과(매장)",
        route: "/menu/results/version-detail-store",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default MainMenuConfig_Store;
