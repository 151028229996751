const MainMenuConfig: object = [
  {
    pages: [
      {
        heading: "대시보드",
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "메뉴",
    route: "/menu",
    pages: [
      {
        sectionTitle: "점검 항목",
        route: "/check-items",
        svgIcon: "media/icons/duotone/Layout/Layout-grid.svg",
        fontIcon: "bi-sticky",
        sub: [
          {
            heading: "점검 카테고리 관리",
            route: "/menu/check-items/category",
          },
          {
            heading: "점검 항목 관리",
            route: "/menu/check-items/items",
          },
          {
            heading: "점검 항목 기준 관리",
            route: "/menu/check-items/versions",
          },
        ],
      },
      {
        heading: "점검 일정 관리",
        route: "/schedules",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layers",
      },
      // {
      //   heading: "QSC 점검",
      //   route: "/qsc",
      //   svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
      //   fontIcon: "bi-layers",
      // },
      {
        sectionTitle: "점검 하기",
        route: "/qsc",
        svgIcon: "media/icons/duotone/Layout/Layout-grid.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "QSC 점검(SV)",
            route: "/menu/qsc/qsc-sv",
          },
        ],
      },
      {
        sectionTitle: "점검 현황",
        route: "/results",
        svgIcon: "media/icons/duotone/Layout/Layout-grid.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "항목 상세 현황",
            route: "/menu/results/item-detail",
          },
          {
            heading: "버전별 점검 결과(SV)",
            route: "/menu/results/version-detail",
          },
        ],
      },
    ],
  },
];

export default MainMenuConfig;
